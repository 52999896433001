import styled from 'styled-components';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  font-weight: 300;
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  margin-top: 25px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  @media screen and (max-width: 991px) {
    width: 90%;
  }

  menu-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 65px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    background-color: #fe5a17;
  }

  menu-header-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 75%;
    height: 65px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  menu-header-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    height: 65px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  menu-icone {
    width: 65px;
    height: 65px;
    margin-right: 25px;
    margin-left: 25px;
    padding: 10px;
    vertical-align: center;
    align-items: center;

    @media screen and (max-width: 480px) {
      width: 52px;
      height: 52px;
    }
  }

  menu-titulo {
    color: #fff;
    font-size: 28px;

    @media screen and (max-width: 480px) {
      font-size: 22px;
    }
  }

  menu-botao {
    position: static;
    left: auto;
    top: 0%;
    right: -60px;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 65px;
    height: 65px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    cursor: pointer;
  }

  botao-p {
    position: relative;
    display: flex;
    width: 40px;
    height: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
  }
`;

export const CorpoContribuicao = styled.div`
  /*display: ${(props) => (props.visible ? 'block' : 'none')};*/

  overflow: hidden;
  width: 100%;
  /*height: 425px;*/
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  /* background-color: #c8c8c8; */
`;

export const SubmenusContribuicao = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 25px;

  a {
    color: #fe5a17;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  w-tab-menu {
    position: relative;
    display: flex;
  }

  w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
  }

  w-tab-pane {
    position: relative;
    display: block;
    /*display: block*/
  }

  submenu-bloco {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none none none solid;
    border-width: 1px 1px 1px 3px;
    border-color: #000 #000 #000 #fe5a17;
  }

  submenu-titulos {
    margin-bottom: 10px;
    color: #fe5a17;
    font-size: 16px;
    font-weight: 700;
  }

  submenu-bloco-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      display: table-column;
    }
  }
  submenu-bloco-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-left: 5px;
    margin-right: 40px;
    @media screen and (max-width: 767px) {
      width: 96%;
    }
  }

  submenu-bloco-coluna-small {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: auto;
    margin-right: 5px;
    margin-left: 5px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      width: 96%;
    }
  }

  submenu-item-coluna {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: contents;
    }
  }

  w-form {
    margin: 0 0 15px;
    margin-bottom: 5px;
  }

  label-observacao {
    /* width: 290px; */
    margin-top: 5px;
    color: #000;
    font-size: 12px;
    text-align: left;
    padding-right: 20px;
  }
`;

export const ContribuicaoField = styled.input`
  width: 115px;
  height: 40px;
  margin-bottom: 0px;
  margin-top: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #fe5a17;
  text-align: center;
  font-size: 16px;
  text-align: right;
  padding-right: 8px;
  @media screen and (max-width: 767px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 510px) {
    font-size: 14px;
  }
`;

export const Form = styled.form`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const BotaoSubmenuRenovacao = styled.button`
  height: 65px;
  /*background-color: #777;*/
  color: #fff;
  border: 1px solid #fff;
  border-style: inset;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  font-size: 16px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;

  background-color: ${(props) => (props.visible ? '#777' : '#2e2e2e')};

  @media screen and (max-width: 767px) {
    height: 55px;
    font-size: 14px;
    padding: 8px;
  }
`;

export const SubmenuCorpoFrame = styled.div`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #000;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 0px none #fe5a17;
  border-top-style: none;
`;

export const SubmenuBotao = styled.button`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #818181;
  -webkit-transition: background-color 200ms
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: #fe5a17;
  }
  @media screen and (max-width: 767px) {
    height: 36px;
    margin: 4px 0;
    font-size: 14px;
  }
`;

export const SubmenuBotaoContribuicao = styled.button`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #818181;
  -webkit-transition: background-color 200ms
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: #c00202;
  }
  @media screen and (max-width: 767px) {
    height: 36px;
    margin: 4px 0;
    font-size: 14px;
  }
`;

export const SliderContribuicao = withStyles({
  root: {
    color: '#777',
    height: 8,
    '&$disabled': {
      color: '#BDBDBD',
    },
  },
  disabled: {},
  thumb: {
    height: 20,
    width: 20,
    color: '#fe5a17',
    backgroundColor: '#fe5a17',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&$disabled': {
      height: 20,
      width: 20,
      color: '#BDBDBD',
      backgroundColor: '#BDBDBD',
      border: '2px solid currentColor',
      marginTop: -6,
      marginLeft: -10,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
    top: -16,
    '& *': {
      background: 'transparent',
      color: '#000',
      fontFamily: ['Open Sans', 'sans-serif'],
    },
  },
  track: {
    height: 8,
    borderRadius: 0,
    '&$disabled': {
      color: '#BDBDBD',
    },
  },
  rail: {
    height: 8,
    borderRadius: 2,
    '&$disabled': {
      color: '#BDBDBD',
    },
  },
  mark: {
    backgroundColor: '#777',
    height: 20,
    width: 2,
    marginTop: 20,
    '&$disabled': {
      backgroundColor: '#BDBDBD',
    },
  },
  markLabel: {
    marginTop: 30,
    fontSize: 12,
    fontFamily: ['Open Sans', 'sans-serif'],
  },
})(Slider);

export const ModalAlert = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    height: 180px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    align-items: center;
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #fe5a17;
    font-size: 16px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #fe5a17;
    }
  }
`;

export const SubmenuObservacoes = styled.div`
  margin-top: 5px;
  color: #000;
  font-size: 14px;
  padding-bottom: 4px;
`;
