/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'; //
import { Base64 } from 'js-base64';
import { Form, Input } from '@rocketseat/unform';
import { RiLoaderLine, RiCloseCircleFill } from 'react-icons/ri';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import api from '../../services/api';
import {
  SubmenuCorpoFrame,
  SubmenuLabels,
  SubmenuInfos,
  SubmenuColunaCheckbox,
  SubmenuBotao,
  SubmenuObservacoes,
  ModalAlert,
  ModalDadosBancarios,
  DadosBancariosItemLabel,
  BotaoTableExcluir,
  ModalConfConta,
} from './styles';
import { ValorMascarar } from '../../services/masks';
import { store } from '../../store';

function Simulador(dadosSimul) {
  const [state] = useState({
    dadosSimulacao: dadosSimul.dadosSimul.dadosSimulacao,
    dadosParcela: dadosSimul.dadosSimul.dadosParcela,
    dadosMargem: dadosSimul.dadosSimul.dadosMargem,
    observacao: dadosSimul.dadosSimul.observacao,
    dadosRegulamento: dadosSimul.dadosRegul,
    dadosBancarios: dadosSimul.dadosBanc,
    listaBancos: dadosSimul.listBancos,
  });
  const [simulOpcional, setSimulOpcional] = useState('');
  const [opcParcela, setOpcParcela] = useState('');
  const [opcEscolha, setOpcEscolha] = useState('');
  const [contrato, setContrato] = useState('');
  const [opcValor, setOpcValor] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [confConta, setConfConta] = useState(false);
  const [flagDadosBancarios, setFlagDadosBancarios] = useState(false);
  const [selected, setSelected] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dadosNovaConta, setNovaConta] = useState({
    codBanco: null,
    codAgencia: null,
    conta: null,
    digito: null,
  });

  if (
    state.dadosSimulacao === null ||
    state.dadosSimulacao === {} ||
    state.dadosSimulacao === undefined ||
    state.dadosRegulamento === null ||
    state.dadosRegulamento === {} ||
    state.dadosRegulamento === undefined
  ) {
    return (
      <>
        <RiLoaderLine color="#008240" size={20} />
      </>
    );
  }

  const { indice } = store.getState().visaoGeral;
  const { token } = store.getState().auth;
  const { patrocinadora } = store.getState().visaoGeral.profile.dadosPlano[
    indice
  ];
  const { codPlano } = store.getState().visaoGeral.profile.dadosPlano[indice];
  const { matricula } = store.getState().visaoGeral.profile.dadosPlano[indice];
  let dataForm = '';
  const urlRegulamento = state.dadosRegulamento[0].link;
  if (
    state.dadosSimulacao[0].dataCredito === undefined ||
    state.dadosSimulacao[0].dataCredito === null
  ) {
    dataForm = '';
  } else {
    const dia = state.dadosSimulacao[0].dataCredito.split('/')[0];
    const mes = state.dadosSimulacao[0].dataCredito.split('/')[1];
    const ano = state.dadosSimulacao[0].dataCredito.split('/')[2];
    dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`;
  }

  function HandleCheck(e, vlrSol, vlrLiq, iof, txJur, prest, qtdParc) {
    // if (prest !== '' || prest !== undefined || prest !== null)
    if (e === true) {
      setSelected(prest);
      setOpcEscolha(
        `{"token": "${token}",
       "organizacao": "${patrocinadora}",
       "matricula": "${matricula}",
       "plano": "${codPlano}",
       "valorSolicitado": "${vlrSol.replace('.', '').replace(',', '.')}",
       "dataCredito": "${dataForm}",
       "valorLiquido": "${vlrLiq.replace('.', '').replace(',', '.')}",
       "iof": "${iof}",
       "taxaJuros": "${txJur}",
       "prestacao": "${prest.replace('.', '').replace(',', '.')}",
       "quantidadeParcela": "${qtdParc}"}`
      );
    } else {
      setOpcEscolha('');
      setSelected('prest');
    }
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
    if (refresh) {
      window.location.reload();
    }
  }

  function HandleToggleDadosBancarios() {
    setFlagDadosBancarios(!flagDadosBancarios);
  }

  function HandleMask(valor, mask) {
    setOpcValor(mask(valor));
  }

  function HandleDisabled(e) {
    if (e === true) {
      setDisabled(true);
    } else setDisabled(false);
  }

  let optionList = '';

  if (state.dadosParcela === undefined) {
    optionList = <option value="1">1x</option>;
  } else {
    optionList = state.dadosParcela.map((index) => (
      <option value={index}>{`${index}x`}</option>
    ));
  }

  let bancosList = '';

  if (state.listaBancos === undefined) {
    bancosList = <option value="1">1x</option>;
  } else {
    bancosList = state.listaBancos.map((n) => (
      <option value={n.codBanco}>{`${n.dcrBanco}`}</option>
    ));
  }

  async function handleSimular({ valor }) {
    const v = valor.replace('.', '').replace(',', '.');

    if (valor === '' || valor === undefined || valor === null) {
      setMsg('Por favor, informe o valor');
      setAlerta(true);
    } else if (
      opcParcela === '' ||
      opcParcela === undefined ||
      opcParcela === null
    ) {
      setMsg('Por favor, escolha a quantidade de parcelas.');
      setAlerta(true);
    } else if (
      parseFloat(v) >
      parseFloat(state.dadosMargem.limiteMaximo.replace(',', '.'))
    ) {
      setMsg(
        `Seu limite é R$${ValorMascarar(
          state.dadosMargem.limiteMaximo
        )}. Por favor, escolha outro valor.`
      );

      setAlerta(true);
      setOpcValor('');
    } else {
      try {
        const parametros = Base64.encode(
          `{"token": "${token}",
          "valor": ${v},
          "parcela": "${opcParcela}"}`
        );

        const parametrosFinal = Base64.encode(parametros);

        const responsePost = await api.post(
          `/wsEmprestimoSimulador.rule?sys=PRT&Entrada=${parametrosFinal}`
        );
        const ds = responsePost.data.dadosSimulacao;

        if (ds.msgRetorno === undefined) {
          setSimulOpcional(
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Parcelas</TableCell>
                    <TableCell align="center">Valor (R$)</TableCell>
                    <TableCell align="center">IOF (R$)</TableCell>
                    <TableCell align="center">Taxa de concessão (%)</TableCell>
                    <TableCell align="center">Valor líquido (R$)</TableCell>
                    <TableCell align="center">Taxa de juros (%)</TableCell>
                    <TableCell align="center">Prestação (R$)</TableCell>
                    <TableCell align="center">Opção</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {ds.quantidadeParcelas == null
                        ? '-'
                        : `${ds.quantidadeParcelas}x`}
                    </TableCell>
                    <TableCell align="center">
                      {ds.valorSolicitado == null ? '-' : ds.valorSolicitado}
                    </TableCell>
                    <TableCell align="center">
                      {ds.iof == null ? '-' : ds.iof}
                    </TableCell>
                    <TableCell align="center">
                      {ds.taxaConcessao == null ? '-' : ds.taxaConcessao}
                    </TableCell>
                    <TableCell align="center">
                      {ds.valorLiquido == null ? '-' : ds.valorLiquido}
                    </TableCell>
                    <TableCell align="center">
                      {ds.taxaJuros == null ? '-' : ds.taxaJuros}
                    </TableCell>
                    <TableCell align="center">
                      {ds.valorParcela == null ? '-' : ds.valorParcela}
                    </TableCell>
                    <TableCell align="center">
                      <SubmenuColunaCheckbox
                        type="checkbox"
                        onChange={(e) => (
                          // eslint-disable-next-line no-sequences
                          HandleDisabled(e.currentTarget.checked),
                          HandleCheck(
                            e.currentTarget.checked,
                            ds.valorSolicitado,
                            ds.valorLiquido,
                            ds.iof,
                            ds.taxaJuros,
                            ds.valorParcela,
                            ds.quantidadeParcelas
                          )
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        } else if (ds.msgRetorno !== undefined) {
          setMsg(ds.msgRetorno);
          setAlerta(true);
        }
      } catch {
        setSimulOpcional(<>Ops, algo deu errado</>);
      }
    }
  }

  function confirmaConta() {
    if (opcEscolha === '' || opcEscolha === null || opcEscolha === undefined) {
      setMsg(
        'Nenhuma opção selecionada. Por favor selecione uma opção para prosseguir.'
      );
      setAlerta(true);
    } else if (
      state.dadosBancarios[0].dcrBanco === undefined ||
      state.dadosBancarios[0].dcrBanco === null
    ) {
      setMsg(
        'Você não possui conta ativa cadastrada. Clique em "incluir dados bancários" e cadastre uma conta ativa.'
      );
      setAlerta(true);
    } else {
      const v = state.dadosBancarios[0];
      const ttt = (
        <p>
          <center>
            Sua conta bancária cadastrada atualmente é: <br />
            <br />
            {v.dcrBanco} <br />
            Agência: {v.agencia} <br />
            Conta: {v.conta}-{v.digito}
            <br />
            <br />
            Os dados estão corretos?
          </center>
        </p>
      );

      setMsg(ttt);
      setConfConta(true);
    }
  }

  async function handleImprimirContrato() {
    if (contrato !== '') {
      try {
        const parametros = Base64.encode(
          `{"token":"${token}","contrato":"${contrato}"}`
        );
        const parametrosFinal = Base64.encode(parametros);

        const response = await api.get(
          `/wsEmprestimoContrato.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        window.open(response.data.link);
      } catch (err) {
        setMsg('Ops, algo deu errado. Tente novamente mais tarde.');
        // setAlerta(true);
      }
    } else {
      setMsg('Contrato não selecionado.');
      // setAlerta(true);
    }
  }

  async function handleSolicitarEmprestimo() {
    /* if (opcEscolha === '' || opcEscolha === null || opcEscolha === undefined) {
      setMsg('Nenhuma opção selecionada');
      setAlerta(true);
    } else { */
    const parametros = Base64.encode(opcEscolha);
    const parametrosFinal = Base64.encode(parametros);
    await api
      .post(`/wsEmprestimoSolicitacao.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then((res) => {
        setMsg('Solicitação efetuada!');
        window.open(res.data.link);
        // handleImprimirContrato();
        setRefresh(true);
        setAlerta(true);
      })
      .catch((res) => {
        if (res.message === 'Request failed with status code 406') {
          setMsg('Você já possui uma solicitação em andamento.');
          setAlerta(true);
        } else if (res.message === 'Request failed with status code 409') {
          setMsg(
            'Você não possui conta bancária cadastrada. Clique no botão "Incluir dados bancários" para cadastrar uma nova.'
          );
          setAlerta(true);
        } else {
          setMsg(
            'Não foi possível efetuar a solicitação agora. Tente novamente mais tarde.'
          );
          setAlerta(true);
        }
      });
    // }
  }

  async function handleExcluir(v) {
    const parametros = Base64.encode(`{
      "token": "${token}",
      "codBanco": "${v.codBanco}",
      "codAgencia": "${v.agencia}",
      "iniVigencia": "${v.iniVigencia}",
      "tipoPessoa": "${v.tipoPessoa}"
    }`);

    const parametrosFinal = Base64.encode(parametros);
    await api
      .post(`/wsDadosBancarios.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then(() => {
        setMsg('Dados bancários excluídos com sucesso');
        setRefresh(true);
        setAlerta(true);
      })
      .catch(() => {
        setMsg('Não foi possivel excluir agora. Tente novamente mais tarde.');
        setAlerta(true);
      });
  }
  async function handleIncluir() {
    if (
      dadosNovaConta.codBanco === null ||
      dadosNovaConta.codBanco === undefined ||
      dadosNovaConta.codBanco === '' ||
      dadosNovaConta.codAgencia === null ||
      dadosNovaConta.codAgencia === undefined ||
      dadosNovaConta.conta === null ||
      dadosNovaConta.conta === undefined ||
      dadosNovaConta.digito === null ||
      dadosNovaConta.digito === undefined
    ) {
      setMsg('Existem campos obrigatórios vazios.');
      setAlerta(true);
    } else {
      const parametros = Base64.encode(`{
        "token": "${token}",
        "codBanco": "${dadosNovaConta.codBanco}",
        "codAgencia": "${dadosNovaConta.codAgencia}",
        "conta": "${dadosNovaConta.conta}",
        "digito": "${dadosNovaConta.digito}",
        "tipoPessoa": "1"
      }`);

      const parametrosFinal = Base64.encode(parametros);
      await api
        .get(`/wsDadosBancariosPUT.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then(() => {
          setMsg('Dados bancários incluídos com sucesso!');
          setRefresh(true);
          setAlerta(true);
        })
        .catch(() => {
          setMsg(
            'Não foi possivel incluir dados bancários agora. Tente novamente mais tarde.'
          );
          setAlerta(true);
        });
    }
  }

  return (
    <SubmenuCorpoFrame>
      <ModalDadosBancarios visible={flagDadosBancarios}>
        <popup-inner>
          <div style={{ direction: 'rtl' }}>
            <button
              type="button"
              style={{
                background: '#FFF',
                border: 'none',
                position: 'fixed',
                marginRight: '-22px',
                marginTop: '-22px',
                height: '25px',
                width: '25px',
                borderRadius: '50%',
              }}
              onClick={() => HandleToggleDadosBancarios()}
            >
              <RiCloseCircleFill color="#c00202" size={25} />
            </button>
          </div>
          <dados-bancarios-linha-titulo>
            <dados-bancarios-titulo>
              Incluir dados bancários
            </dados-bancarios-titulo>
          </dados-bancarios-linha-titulo>
          <dados-bancarios-bloco>
            <form>
              <dados-bancarios-item>
                <DadosBancariosItemLabel>Banco</DadosBancariosItemLabel>
                <dados-bancarios-form>
                  <dados-bancarios-form-frame>
                    <select
                      style={{
                        color: '#333333',
                        backgroundColor: '#ffffff',
                        border: '1px solid #cccccc',
                        maxWidth: '240px',
                      }}
                      onChange={(e) =>
                        setNovaConta({
                          ...dadosNovaConta,
                          codBanco: e.target.value,
                        })
                      }
                    >
                      <option value="">Selecione...</option>
                      {bancosList}
                    </select>
                  </dados-bancarios-form-frame>
                </dados-bancarios-form>
              </dados-bancarios-item>
              <dados-bancarios-item-sem-margem>
                <DadosBancariosItemLabel>Agência</DadosBancariosItemLabel>
                <dados-bancarios-form>
                  <dados-bancarios-form-frame>
                    <input
                      type="number"
                      max={99999}
                      placeholder="Inserir info"
                      style={{
                        maxWidth: '240px',
                      }}
                      onChange={(e) =>
                        setNovaConta({
                          ...dadosNovaConta,
                          codAgencia: e.target.value,
                        })
                      }
                    />
                  </dados-bancarios-form-frame>
                </dados-bancarios-form>
              </dados-bancarios-item-sem-margem>
              <dados-bancarios-item-sem-margem>
                <DadosBancariosItemLabel>
                  Número da conta
                </DadosBancariosItemLabel>
                <dados-bancarios-form>
                  <dados-bancarios-form-frame>
                    <input
                      type="text"
                      maxLength="20"
                      placeholder="Inserir info"
                      style={{
                        maxWidth: '240px',
                      }}
                      onChange={(e) =>
                        setNovaConta({
                          ...dadosNovaConta,
                          conta: e.target.value,
                        })
                      }
                    />
                  </dados-bancarios-form-frame>
                </dados-bancarios-form>
              </dados-bancarios-item-sem-margem>
              <dados-bancarios-item-sem-margem>
                <DadosBancariosItemLabel>
                  Dígito verificador
                </DadosBancariosItemLabel>
                <dados-bancarios-form>
                  <dados-bancarios-form-frame>
                    <input
                      type="text"
                      maxLength="1"
                      placeholder="Inserir info"
                      style={{
                        maxWidth: '240px',
                      }}
                      onChange={(e) =>
                        setNovaConta({
                          ...dadosNovaConta,
                          digito: e.target.value,
                        })
                      }
                    />
                  </dados-bancarios-form-frame>
                </dados-bancarios-form>
              </dados-bancarios-item-sem-margem>

              <dados-bancarios-item-sem-margem>
                <button
                  type="button"
                  style={{
                    maxWidth: '240px',
                  }}
                  onClick={() => handleIncluir()}
                >
                  Incluir
                </button>
              </dados-bancarios-item-sem-margem>
            </form>
          </dados-bancarios-bloco>
          <br />
          <dados-bancarios-bloco>
            <dados-bancarios-subtitulo>
              Conta cadastrada
            </dados-bancarios-subtitulo>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Banco</TableCell>
                    <TableCell align="center">Agência</TableCell>
                    <TableCell align="center">Número da conta</TableCell>
                    <TableCell align="center">
                      Dígito verificador da conta
                    </TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.dadosBancarios === undefined ||
                    state.dadosBancarios === null ||
                    state.dadosBancarios === [] ||
                    state.dadosBancarios === '[]' ||
                    state.dadosBancarios === '' ? (
                    <TableRow>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center" />
                    </TableRow>
                  ) : (
                    state.dadosBancarios.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {row.dcrBanco == null || row.dcrBanco === undefined
                            ? '-'
                            : row.dcrBanco}
                        </TableCell>
                        <TableCell align="center">
                          {row.agencia == null || row.agencia === undefined
                            ? '-'
                            : row.agencia}
                        </TableCell>
                        <TableCell align="center">
                          {row.conta == null || row.conta === undefined
                            ? '-'
                            : row.conta}
                        </TableCell>
                        <TableCell align="center">
                          {row.digito == null || row.digito === undefined
                            ? '-'
                            : row.digito}
                        </TableCell>
                        <TableCell align="center">
                          <BotaoTableExcluir
                            type="button"
                            onClick={() => handleExcluir(row)}
                          >
                            Excluir
                          </BotaoTableExcluir>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </dados-bancarios-bloco>
          {/* <div>
            <button type="button" onClick={HandleToggleDadosBancarios}>
              OK
            </button>
          </div> */}
        </popup-inner>
      </ModalDadosBancarios>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <ModalConfConta visible={confConta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button
              type="button"
              onClick={() => {
                setConfConta(false);
                handleSolicitarEmprestimo();
              }}
            >
              Está correto!
            </button>
            <button
              type="button"
              onClick={() => {
                setConfConta(false);
                setFlagDadosBancarios(true);
              }}
            >
              Quero atualizar estes dados
            </button>
          </div>
        </popup-inner>
      </ModalConfConta>
      <submenu-bloco>
        <submenu-titulos>Limites</submenu-titulos>
        <submenu-bloco-parte01>
          <submenu-bloco-parte02>
            <submenu-item-conteudo>
              <SubmenuLabels>Prestação máxima (R$)</SubmenuLabels>
              <SubmenuInfos>
                {ValorMascarar(state.dadosMargem.prestacaoMaxima)}
              </SubmenuInfos>
            </submenu-item-conteudo>
            <submenu-item-conteudo>
              <SubmenuLabels>Limite máximo (R$)</SubmenuLabels>
              <SubmenuInfos>
                {ValorMascarar(state.dadosMargem.limiteMaximo)}
              </SubmenuInfos>
            </submenu-item-conteudo>
            {/* </submenu-bloco-parte02>
          <submenu-bloco-parte02> */}
            <submenu-item-conteudo>
              <SubmenuLabels>Data de crédito</SubmenuLabels>
              <SubmenuInfos>{state.dadosSimulacao[0].dataCredito}</SubmenuInfos>
            </submenu-item-conteudo>
          </submenu-bloco-parte02>
        </submenu-bloco-parte01>
      </submenu-bloco>
      <submenu-bloco>
        <submenu-titulos>Simulação</submenu-titulos>
        <submenu-bloco-parte01>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Parcelas</TableCell>
                  <TableCell align="center">Valor (R$)</TableCell>
                  <TableCell align="center">IOF (R$)</TableCell>
                  <TableCell align="center">Taxa de concessão (%)</TableCell>
                  <TableCell align="center">Valor líquido (R$)</TableCell>
                  <TableCell align="center">Taxa de juros (%)</TableCell>
                  <TableCell align="center">Prestação (R$)</TableCell>
                  <TableCell align="center">Opção</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.dadosSimulacao === undefined ||
                  state.dadosSimulacao === null ||
                  state.dadosSimulacao === [] ||
                  state.dadosSimulacao === '[]' ||
                  state.dadosSimulacao === '' ? (
                  <TableRow>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                ) : (
                  state.dadosSimulacao.flatMap((row, index) =>
                    row.msgRetorno === null || row.msgRetorno === undefined ? (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {row.quantidadeParcelas == null ||
                            row.quantidadeParcelas === undefined
                            ? '-'
                            : `${row.quantidadeParcelas}x`}
                        </TableCell>
                        <TableCell align="center">
                          {row.valorSolicitado == null ||
                            row.valorSolicitado === undefined
                            ? '-'
                            : row.valorSolicitado}
                        </TableCell>
                        <TableCell align="center">
                          {row.iof == null || row.iof === undefined
                            ? '-'
                            : row.iof}
                        </TableCell>
                        <TableCell align="center">
                          {row.taxaConcessao == null ||
                            row.taxaConcessao === undefined
                            ? '-'
                            : row.taxaConcessao}
                        </TableCell>
                        <TableCell align="center">
                          {row.valorLiquido == null ||
                            row.valorLiquido === undefined
                            ? '-'
                            : row.valorLiquido}
                        </TableCell>
                        <TableCell align="center">
                          {row.taxaJuros == null || row.taxaJuros === undefined
                            ? '-'
                            : row.taxaJuros}
                        </TableCell>
                        <TableCell align="center">
                          {row.valorParcela == null ||
                            row.valorParcela === undefined
                            ? '-'
                            : row.valorParcela}
                        </TableCell>
                        <TableCell align="center">
                          <SubmenuColunaCheckbox
                            type="checkbox"
                            disabled={disabled}
                            checked={
                              row.valorParcela === selected &&
                              opcEscolha !== null &&
                              opcEscolha !== ''
                            }
                            onChange={(e) =>
                              HandleCheck(
                                e.currentTarget.checked,
                                row.valorSolicitado,
                                row.valorLiquido,
                                row.iof,
                                row.taxaJuros,
                                row.valorParcela,
                                row.quantidadeParcelas
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </submenu-bloco-parte01>
      </submenu-bloco>

      <submenu-bloco>
        <submenu-titulos>Escolher outra opção</submenu-titulos>
        <div-block-9>
          <w-form>
            <Form onSubmit={handleSimular}>
              <Input
                type="text"
                placeholder="Valor"
                name="valor"
                value={opcValor}
                onChange={(e) => HandleMask(e.target.value, ValorMascarar)}
              />
              {/* <label>,00</label> */}
              <select
                nome="parcela"
                onChange={(e) => setOpcParcela(e.target.value)}
              >
                <option value="">Escolha uma...</option>
                {optionList}
              </select>
              <button type="submit">Simular</button>
            </Form>
          </w-form>
        </div-block-9>
        <>{simulOpcional}</>
      </submenu-bloco>

      <submenu-bloco>
        <submenu-titulos>Atenção – Procedimentos para Aprovação</submenu-titulos>
        <submenu-item-conteudo-ajustado>
          {/* <SubmenuObservacoes>{state.observacao}</SubmenuObservacoes> */}
          <SubmenuObservacoes>
            <p>
              A solicitação do empréstimo será submetida à análise e ficará
              pendente de aprovação. Após realizar a solicitação, envie os
              documentos necessários por meio do MyService. Para acessar,{' '}
              <a
                href="https://pepsico.service-now.com/esc?id=sc_cat_item&sys_id=3e1243ec1b965910b0548734604bcb48"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>clique aqui</strong>
              </a>
              .
            </p>
            <br />
            <p>
              Documentos necessários: contrato e autorização de desconto em folha assinados, comprovante dos dados bancários e caso tenha empréstimo com o Bradesco, termo de compromisso de quitação.
            </p>
            <br/>
            <p>
              <strong>Importante: </strong>É possível solicitar um empréstimo da Prev Pepsico para quitar o empréstimo consignado com o Bradesco, desde que o valor disponível na Prev Pepsico seja superior ao saldo devedor no Bradesco.
            </p>
          </SubmenuObservacoes>
        </submenu-item-conteudo-ajustado>
      </submenu-bloco>

      <submenu-bloco>
        <submenu-bloco-parte01>
          <SubmenuBotao onClick={confirmaConta}>
            {/* //onClick={handleSolicitarEmprestimo}> */}
            Solicitar empréstimo
          </SubmenuBotao>
          <SubmenuBotao onClick={HandleToggleDadosBancarios}>
            Incluir dados bancários
          </SubmenuBotao>
          <a href={urlRegulamento} target="_blank" rel="noopener noreferrer">
            <SubmenuBotao name="btnRegulamento">
              Consultar o regulamento
            </SubmenuBotao>
          </a>
        </submenu-bloco-parte01>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}
export default Simulador;
